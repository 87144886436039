import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationInfo, NotificationInfoType } from './notification.schema';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  private notificationSource = new Subject<any>();
  public notification$ = this.notificationSource.asObservable();

  createNotification(
      notificationType: NotificationInfoType,
      message: string,
      millisecondsUntilClose: number = 0,
      isDismissible: boolean = true
    ) {
    const notificationInfo = new NotificationInfo();
    notificationInfo.type = notificationType;
    notificationInfo.message = message;
    notificationInfo.millisecondsUntilClose = millisecondsUntilClose;
    notificationInfo.isDismissible = isDismissible;
    this.createNotificationUsingNotificationInfo(notificationInfo);
  }

  createNotificationUsingNotificationInfo(notificationInfo: NotificationInfo) {
    this.notificationSource.next(notificationInfo);
  }

}
