import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationInfoType } from '../../services/notification.schema';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public configurationService: ConfigurationService,
    private notificationService: NotificationService
  ) { }

  public form: FormGroup;
  formSubmitted = false;
  redirectUrl = 'dashboard';

  ngOnInit(): void {
    if (this.authenticationService.currentUser) {
      this.router.navigate([`/${this.redirectUrl}`]);
    }
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.route.queryParams.subscribe((params) => {
      if (params.confirmed) {
        this.notificationService.createNotification(
          NotificationInfoType.Success,
          'Your account has been confirmed',
          1500,
          false
        );
      }
      if (params.rurl) {
        this.redirectUrl = params.rurl;
      }
    });
  }

  login() {
    this.formSubmitted = true;
    if (!this.form.controls.email.errors && !this.form.controls.password.errors) {
      this.authenticationService.login(this.form.get('email').value, this.form.get('password').value, this).subscribe((result: any) => {
        if (result.error) {
          this.notificationService.createNotification(
            NotificationInfoType.Danger,
            result.error.message,
            2000,
            false
          );
        } else if (result.AuthenticationResult) {
          this.notificationService.createNotification(
            NotificationInfoType.Success,
            'You are now signed in.',
            1500,
            false
          );
          this.authenticationService.createSession(this.form.get('email').value, result.AuthenticationResult);
          this.router.navigate([`/${this.redirectUrl}`]);
        } else {
          this.notificationService.createNotification(
            NotificationInfoType.Warning,
            'There is a problem with our sign in service. Please try again later',
            0,
            true
          );
        }
      });
    }
  }

  handleApiError(errorResponse: HttpErrorResponse) {
    return new Observable((observer) => {
      observer.next(errorResponse);
    });
  }

}
