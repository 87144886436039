import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { ConfigurationService } from '../../services/configuration.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationInfoType } from '../../services/notification.schema';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(
    public authenticationService: AuthenticationService,
    public configurationService: ConfigurationService,
    private notificationService: NotificationService,
    private router: Router
    ) { }

  showSectionDropdown = false;
  showUserDropdown = false;

  ngOnInit(): void {
  }

  toggleSectionDropdown(): void {
    this.showSectionDropdown = !this.showSectionDropdown;
  }

  toggleUserDropdown(): void {
    this.showUserDropdown = !this.showUserDropdown;
  }

  parseEmailInitials(): string {
    let emailUsername = this.authenticationService.currentUser.email.substring(0, this.authenticationService.currentUser.email.indexOf('@'));
    const emailParts = emailUsername.split('.')
    let initials = '';
    emailParts.forEach((part) => {
      initials += part[0];
    });
    return initials.substring(0, 3).toUpperCase();
  }

  signOut(): void {
    this.authenticationService.logout();
    this.notificationService.createNotification(
      NotificationInfoType.Success,
      'You are now signed out.',
      1500,
      false
    );
    this.router.navigate(['/login']);
  }

}
