<h1 class="text-xl md:text-2xl mb-4 font-bold">
  Create New Account
</h1>
<form [formGroup]="form">
  <div class="md:flex md:items-center mx-4 mb-4 relative">
    <div class="md:w-1/3">
      <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="email">
        Email Address
      </label>
    </div>
    <div class="md:w-2/3 lg:w-1/2">
      <input
          aria-label="Email Address"
          name="email"
          formControlName="email"
          type="email"
          required
          class="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-600
            placeholder-gray-500 text-gray-900 rounded-t-md
            focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-xl sm:leading-5"
          placeholder="me@mycompany.com"
          [ngClass]="{ 'bg-red-200': this.formSubmitted && this.form.controls.email.errors }"
        />
    </div>
  </div>
  <div class="md:flex md:items-center mx-4 mb-4 relative">
    <div class="md:w-1/3">
      <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="password">
        Password
      </label>
    </div>
    <div class="md:w-2/3 lg:w-1/2">
      <input
        aria-label="Password"
        name="password"
        formControlName="password"
        type="password"
        required
        class="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-600
            placeholder-gray-500 text-gray-900 rounded-t-md
            focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-xl sm:leading-5"
        placeholder="******"
        [ngClass]="{ 'bg-red-200': this.formSubmitted && this.form.controls.password.errors }"
        />
    </div>
  </div>
  <div class="md:flex md:items-center mx-4 mb-4 relative">
    <div class="md:w-1/3">
      <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="passwordConfirmation">
        Repeat Password
      </label>
    </div>
    <div class="md:w-2/3 lg:w-1/2">
      <input
        aria-label="Repeat Password"
        name="passwordConfirmation"
        formControlName="passwordConfirmation"
        type="password"
        required
        class="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-600
            placeholder-gray-500 text-gray-900 rounded-t-md
            focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-xl sm:leading-5"
        placeholder="******"
        [ngClass]="{ 'bg-red-200': this.formSubmitted && this.form.controls.passwordConfirmation.errors }"
        />
    </div>
  </div>
  <div class="mt-5 sm:mt-8 flex justify-center">
    <button
      (click)="register()"
      class="px-8 py-3 mx-1 border border-transparent text-base leading-6 font-medium rounded-md shadow
        text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline
        transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
      >
      Create Account
    </button>
  </div>
</form>
