import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient,
    private httpBackend: HttpBackend,
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService
  ) {
    this.noAuthHttpClient = new HttpClient(httpBackend);
  }

  private noAuthHttpClient: HttpClient;

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        Authorization: this.authenticationService.currentUser.accessToken
      })
    };
  }

  getAllDocuments(queryString: string, callback: HttpErrorCallback): any {
    return this.httpClient
      .get<any[]>(this.configurationService.dataApiUrl + '/documents' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getDocument(documentID: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any>(this.configurationService.dataApiUrl + '/documents/' + documentID, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  postDocument(json: string, callback: HttpErrorCallback): any {
    const body = JSON.parse(json);
    return this.httpClient
      .post<any>(this.configurationService.dataApiUrl + '/documents', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  patchDocument(documentID: string, json: string, callback: HttpErrorCallback): any {
    const body = JSON.parse(json);
    return this.httpClient
      .patch<any>(this.configurationService.dataApiUrl + '/documents/' + documentID, body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  deleteDocument(documentID: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .delete<any>(this.configurationService.dataApiUrl + '/documents/' + documentID, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getDocumentContent(documentID: string, queryString: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any>(this.configurationService.dataApiUrl + '/documents/' + documentID + '/content' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getDocumentTags(documentID: string, queryString: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any[]>(this.configurationService.dataApiUrl + '/documents/' + documentID + '/tags' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  postDocumentTag(documentID: string, json: string, callback: HttpErrorCallback): Observable<any> {
    const body = JSON.parse(json);
    return this.httpClient
      .post<any>(this.configurationService.dataApiUrl + '/documents/' + documentID + '/tags', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getDocumentTag(documentID: string, key: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any>(
        this.configurationService.dataApiUrl + '/documents/' + documentID + '/tags/' + encodeURIComponent(key), this.getHttpOptions()
      )
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  deleteDocumentTag(documentID: string, key: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .delete<any>(
        this.configurationService.dataApiUrl + '/documents/' + documentID + '/tags/' + encodeURIComponent(key), this.getHttpOptions()
      )
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  postDocumentFormat(documentID: string, json: string, callback: HttpErrorCallback): Observable<any> {
    const body = JSON.parse(json);
    return this.httpClient
      .post<any>(this.configurationService.dataApiUrl + '/documents/' + documentID + '/formats', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getDocumentVersions(documentID: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<string>(this.configurationService.dataApiUrl + '/documents/' + documentID + '/versions', this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getDocumentsUpload(queryString: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<string>(this.configurationService.dataApiUrl + '/documents/upload' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getDocumentUpload(documentID: string, queryString: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<string>(this.configurationService.dataApiUrl + '/documents/' + documentID + '/upload' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getDocumentUrl(documentID: string, queryString: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any>(this.configurationService.dataApiUrl + '/documents/' + documentID + '/url' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  postSearch(json: string, queryString: string, callback: HttpErrorCallback): Observable<any> {
    const body = JSON.parse(json);
    return this.httpClient
      .post<any[]>(this.configurationService.dataApiUrl + '/search' + queryString, body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getAllSites(queryString: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any[]>(this.configurationService.dataApiUrl + '/sites' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getAllPresets(queryString: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any[]>(this.configurationService.dataApiUrl + '/presets' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  postPreset(json: string, callback: HttpErrorCallback): any {
    const body = JSON.parse(json);
    return this.httpClient
      .post<any>(this.configurationService.dataApiUrl + '/presets', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  deletePreset(presetID: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .delete<any>(this.configurationService.dataApiUrl + '/presets/' + presetID, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getPresetTags(presetID: string, queryString: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any[]>(this.configurationService.dataApiUrl + '/presets/' + presetID + '/tags' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  postPresetTag(presetID: string, json: string, callback: HttpErrorCallback): Observable<any> {
    const body = JSON.parse(json);
    return this.httpClient
      .post<any>(this.configurationService.dataApiUrl + '/presets/' + presetID + '/tags', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  deletePresetTag(presetID: string, key: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .delete<any>(
        this.configurationService.dataApiUrl + '/presets/' + presetID + '/tags/' + encodeURIComponent(key), this.getHttpOptions()
      )
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getVersion(callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any>(
        this.configurationService.dataApiUrl + '/version', this.getHttpOptions()
      )
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getAllWebhooks(queryString: string, callback: HttpErrorCallback): Observable<any> {
    return this.httpClient
      .get<any[]>(this.configurationService.dataApiUrl + '/webhooks' + queryString, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  patchWebhook(webhookID: string, json: string, callback: HttpErrorCallback): any {
    const body = JSON.parse(json);
    return this.httpClient
      .patch<any>(this.configurationService.dataApiUrl + '/webhooks/' + webhookID, body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  postWebhook(json: string, callback: HttpErrorCallback): any {
    const body = JSON.parse(json);
    return this.httpClient
      .post<any>(this.configurationService.dataApiUrl + '/webhooks', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  deleteWebhook(webhookID: string, callback: HttpErrorCallback): any {
    return this.httpClient
      .delete<any>(this.configurationService.dataApiUrl + '/webhooks/' + webhookID, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getWebhook(webhookID: string, callback: HttpErrorCallback): any {
    return this.httpClient
      .get<any>(this.configurationService.dataApiUrl + '/webhooks/' + webhookID, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  getWebhookTags(webhookID: string, callback: HttpErrorCallback): any {
    return this.httpClient
      .get<any>(this.configurationService.dataApiUrl + '/webhooks/' + webhookID + '/tags', this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }
}

export interface HttpErrorCallback {
  handleApiError(errorResponse: HttpErrorResponse): any;
}
