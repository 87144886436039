import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { catchError, shareReplay, map } from 'rxjs/operators';
import * as ExpiredStorage from 'expired-storage';
import { ConfigurationService } from './configuration.service';
import { LoggedInUser } from './authentication.schema';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient,
    private configurationService: ConfigurationService
  ) {
    this.expiredStorage = new ExpiredStorage();
    this.currentUserSubject = new BehaviorSubject<LoggedInUser>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUserHasValidTokenSubject = new BehaviorSubject<boolean>(
      JSON.parse(this.expiredStorage.getItem('currentUserHasValidToken'))
    );
  }

  private expiredStorage: ExpiredStorage;
  private currentUserSubject: BehaviorSubject<LoggedInUser>;
  private currentUserHasValidTokenSubject: BehaviorSubject<boolean>;
  private authenticationChangeSource = new Subject<any>();
  public authenticationChange$ = this.authenticationChangeSource.asObservable();

  public get currentUser(): LoggedInUser {
    return this.currentUserSubject.value;
  }

  public get currentUserHasValidToken(): boolean {
    if (this.expiredStorage.getItem('currentUserHasValidToken')) {
      return true;
    }
    return false;
  }

  public get currentUserTokenTimeLeft(): number {
    return this.expiredStorage.getTimeLeft('currentUserHasValidToken');
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'content-type': 'application/json; charset=utf-8'
      })
    };
  }

  login(username: string, password: string, callback: HttpErrorCallback): Observable<any> {
    const body = {
      username,
      password
    }
    return this.httpClient
      .post<any>(this.configurationService.accountApiUrl + '/login', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  register(username: string, password: string, callback: HttpErrorCallback): Observable<any> {
    const body = {
      username,
      password,
      createNewGroup: true
    }
    return this.httpClient
      .post<any>(this.configurationService.accountApiUrl + '/register', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  logout() {
    this.closeSession();
    this.authenticationChangeSource.next();
  }

  forgotPassword(username: string, callback: HttpErrorCallback): Observable<any> {
    const body = {
      username
    }
    return this.httpClient
      .post<any>(this.configurationService.accountApiUrl + '/forgotPassword', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  changePassword(accessToken: string, previousPassword: string, password: string, callback: HttpErrorCallback) {
    const body = {
      accessToken,
      password,
      previousPassword
    }
    return this.httpClient
      .post<any>(this.configurationService.accountApiUrl + '/changePassword', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  changePasswordFromReset(username: string, clientId: string, code: number, password: string, callback: HttpErrorCallback): Observable<any> {
    const body = {
      username,
      clientId,
      code,
      password
    }
    return this.httpClient
      .post<any>(this.configurationService.accountApiUrl + '/resetPassword', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  refreshToken(username: string, refreshToken: string, callback: HttpErrorCallback): Observable<any> {
    const body = {
      username,
      refreshToken
    }
    return this.httpClient
      .post<any>(this.configurationService.accountApiUrl + '/refreshToken', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  respondToAuthChallenge(username: string, userStatus: string, session: string, password: string, callback: HttpErrorCallback) {
    const body = {
      userStatus,
      password,
      session,
      username
    }
    return this.httpClient
      .post<any>(this.configurationService.accountApiUrl + '/respondToAuthChallenge', body, this.getHttpOptions())
      .pipe(shareReplay(1),
        catchError(callback.handleApiError));
  }

  createSession(username, authResult: any) {
    const user = new LoggedInUser();
    user.email = username;
    user.accessToken = authResult.AccessToken;
    user.idToken = authResult.IdToken;
    user.refreshToken = authResult.RefreshToken;
    localStorage.setItem('currentUser', JSON.stringify(user));
    const tokenExpiresIn = parseInt(authResult.ExpiresIn, 10) - 100;
    this.expiredStorage.setJson('currentUserHasValidToken', true, tokenExpiresIn);
    this.currentUserSubject.next(user);
    this.currentUserHasValidTokenSubject.next(true);
    this.authenticationChangeSource.next();
  }

  verifyToken(): Observable<boolean> {
    if (!this.currentUserHasValidToken || this.currentUserTokenTimeLeft < this.configurationService.triggerRefreshOnTimeLeft) {
      return new Observable((observer) => {
        this.refreshToken(this.currentUser.email, this.currentUser.refreshToken, this).subscribe((result) => {
          if (result.error) {
            setTimeout(() => {
              this.authenticationChangeSource.next();
            }, 100);
            observer.next(false);
          } else if (result.AuthenticationResult) {
            this.createSession(this.currentUser.email, result.AuthenticationResult);
            observer.next(true);
          } else {
            setTimeout(() => {
              this.authenticationChangeSource.next();
            }, 100);
            observer.next(false);
          }
        });
      });
    }

    return new Observable((observer) => {
      observer.next(true);
    });
  }
  
  closeSession() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  handleApiError(errorResponse: HttpErrorResponse) {
    return new Observable((observer) => {
      observer.next(errorResponse);
    });
  }

}

export interface HttpErrorCallback {
  handleApiError(errorResponse: HttpErrorResponse): any;
}
