import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
    ) { }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const queryParams: any = {};
    if (next.queryParams.userStatus) {
      switch (next.queryParams.userStatus) {
        case 'NEW_PASSWORD_REQUIRED':
          this.router.navigate(['/confirmRegistration'], { queryParams: next.queryParams });
          return false;
      }
    }
    if (next.routeConfig.path) {
      queryParams.rurl = next.routeConfig.path;
    }
    if (this.authenticationService.currentUser) {
      this.authenticationService.verifyToken().subscribe((valid: boolean) => {
        return new Observable<boolean>((observer) => {
          if (!valid) {
            this.router.navigate(['/login'], { queryParams });
          }
          observer.next(valid);
        });
      });
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams });
      return false;
    }
  }

}
