<div
  *ngIf="authenticationService.currentUser && configurationService.currentBrand !== '24hourcharts'"
  class="shadow-xl h-16 fixed bottom-0 mt-12 md:h-full z-20 w-full md:w-48"
  [ngClass]="{
    'bg-proTeal-700': configurationService.currentBrand === 'formkiq',
    'bg-gray-900': configurationService.currentBrand === '24hourcharts',
    'md:hidden': !configurationService.showSidebarByDefault
  }"
  >
  <div
    class="md:mt-12 md:w-48 md:fixed md:left-0 md:top-0 content-center md:content-start text-left justify-between"
    >
    <ul class="list-reset flex flex-row md:flex-col mt-2 py-0 md:py-3 px-1 md:px-4 text-center md:text-left">
      <li *ngIf="configurationService.currentSection === 'documents'" class="mr-3 flex-1">
        <a
          [routerLink]="['/documents']"
          class="block py-1 md:py-3 pl-1 align-middle no-underline border-b-2"
          [ngClass]="{
            'border-proTeal-500 text-proTeal-200': configurationService.currentPage === 'documentsRecent' && configurationService.currentBrand === 'formkiq',
            'border-red-600 text-white': configurationService.currentPage === 'documentsRecent' && configurationService.currentBrand === '24hourcharts',
            'border-gray-800 text-gray-50 hover:border-proTeal-500 hover:text-white': configurationService.currentPage !== 'documentsRecent' && configurationService.currentBrand === 'formkiq',
            'border-gray-800 text-gray-300 hover:border-red-600 hover:text-white': configurationService.currentPage !== 'documentsRecent' && configurationService.currentBrand === '24hourcharts'
          }"
          >
          <span class="text-xs md:text-base">
            Recent
            <span class="hidden md:inline">
              Documents
            </span>
          </span>
        </a>
      </li>
      <li *ngIf="configurationService.currentSection === 'documents'" class="mr-3 flex-1">
        <a
          [routerLink]="['/documents/search']"
          class="block py-1 md:py-3 pl-1 align-middle no-underline border-b-2"
          [ngClass]="{
            'border-proTeal-500 text-proTeal-200': configurationService.currentPage === 'documentsSearch' && configurationService.currentBrand === 'formkiq',
            'border-red-600 text-white': configurationService.currentPage === 'documentsSearch' && configurationService.currentBrand === '24hourcharts',
            'border-gray-800 text-gray-50 hover:border-proTeal-500 hover:text-white': configurationService.currentPage !== 'documentsSearch' && configurationService.currentBrand === 'formkiq',
            'border-gray-800 text-gray-300 hover:border-red-600 hover:text-white': configurationService.currentPage !== 'documentsSearch' && configurationService.currentBrand === '24hourcharts'
          }"
          >
          <span class="text-xs md:text-base">
            <span class="hidden md:inline">
              Document
            </span>
            Search
          </span>
        </a>
      </li>
      <li *ngIf="configurationService.currentSection === 'documents'" class="mr-3 flex-1">
        <a
          [routerLink]="['/documents/add']"
          class="block py-1 md:py-3 pl-1 align-middle no-underline border-b-2"
          [ngClass]="{
            'border-proTeal-500 text-proTeal-200': configurationService.currentPage === 'documentsAdd' && configurationService.currentBrand === 'formkiq',
            'border-red-600 text-white': configurationService.currentPage === 'documentsAdd' && configurationService.currentBrand === '24hourcharts',
            'border-gray-800 text-gray-50 hover:border-proTeal-500 hover:text-white': configurationService.currentPage !== 'documentsAdd' && configurationService.currentBrand === 'formkiq',
            'border-gray-800 text-gray-300 hover:border-red-600 hover:text-white': configurationService.currentPage !== 'documentsAdd' && configurationService.currentBrand === '24hourcharts'
          }"
          >
          <span class="text-xs md:text-base">
            Add
            <span class="md:hidden">
              New
            </span>
            <span class="hidden md:inline">
              Documents
            </span>
          </span>
        </a>
      </li>
      <li *ngIf="configurationService.currentSection === 'documents'" class="mr-3 flex-1">
        <a
          [routerLink]="['/documents/tagging']"
          class="block py-1 md:py-3 pl-1 align-middle no-underline border-b-2"
          [ngClass]="{
            'border-proTeal-500 text-proTeal-200': configurationService.currentPage === 'documentsTagging' && configurationService.currentBrand === 'formkiq',
            'border-red-600 text-white': configurationService.currentPage === 'documentsTagging' && configurationService.currentBrand === '24hourcharts',
            'border-gray-800 text-gray-50 hover:border-proTeal-500 hover:text-white': configurationService.currentPage !== 'documentsTagging' && configurationService.currentBrand === 'formkiq',
            'border-gray-800 text-gray-300 hover:border-red-600 hover:text-white': configurationService.currentPage !== 'documentsTagging' && configurationService.currentBrand === '24hourcharts'
          }"
          >
          <span class="text-xs md:text-base">
            <span class="hidden md:inline">
              Document
            </span>
            Tagging
          </span>
        </a>
      </li>
      <li *ngIf="configurationService.currentSection === 'integrations'" class="mr-3 flex-1">
        <a
          [routerLink]="['/integrations/webhooks']"
          class="block py-1 md:py-3 pl-1 align-middle no-underline border-b-2"
          [ngClass]="{
            'border-proTeal-500 text-proTeal-200': configurationService.currentPage === 'webhooks' && configurationService.currentBrand === 'formkiq',
            'border-red-600 text-white': configurationService.currentPage === 'webhooks' && configurationService.currentBrand === '24hourcharts',
            'border-gray-800 text-gray-50 hover:border-proTeal-500 hover:text-white': configurationService.currentPage !== 'webhooks' && configurationService.currentBrand === 'formkiq',
            'border-gray-800 text-gray-300 hover:border-red-600 hover:text-white': configurationService.currentPage !== 'webhooks' && configurationService.currentBrand === '24hourcharts'
          }"
          >
          <span class="text-xs md:text-base">
            Webhooks
          </span>
        </a>
      </li>
      <li *ngIf="configurationService.currentSection === 'integrations'" class="mr-3 flex-1">
        <a
          [routerLink]="['/integrations/api']"
          class="block py-1 md:py-3 pl-1 align-middle no-underline border-b-2"
          [ngClass]="{
            'border-proTeal-500 text-proTeal-200': configurationService.currentPage === 'api' && configurationService.currentBrand === 'formkiq',
            'border-red-600 text-white': configurationService.currentPage === 'api' && configurationService.currentBrand === '24hourcharts',
            'border-gray-800 text-gray-50 hover:border-proTeal-500 hover:text-white': configurationService.currentPage !== 'api' && configurationService.currentBrand === 'formkiq',
            'border-gray-800 text-gray-300 hover:border-red-600 hover:text-white': configurationService.currentPage !== 'api' && configurationService.currentBrand === '24hourcharts'
          }"
          >
          <span class="text-xs md:text-base">
            API
            <span class="hidden md:inline">
              Explorer
            </span>
          </span>
        </a>
      </li>
      <li *ngIf="configurationService.currentSection === 'visuals'" class="mr-3 flex-1">
        <a
          [routerLink]="['/visuals/charts']"
          class="block py-1 md:py-3 pl-1 align-middle no-underline border-b-2"
          [ngClass]="{
            'border-proTeal-500 text-proTeal-200': configurationService.currentPage === 'chartsExplorer' && configurationService.currentBrand === 'formkiq',
            'border-red-600 text-white': configurationService.currentPage === 'chartsExplorer' && configurationService.currentBrand === '24hourcharts',
            'border-gray-800 text-gray-50 hover:border-proTeal-500 hover:text-white': configurationService.currentPage !== 'chartsExplorer' && configurationService.currentBrand === 'formkiq',
            'border-gray-800 text-gray-300 hover:border-red-600 hover:text-white': configurationService.currentPage !== 'chartsExplorer' && configurationService.currentBrand === '24hourcharts'
          }"
          >
          <span class="text-xs md:text-base">
            Charts
            <span class="hidden md:inline">
              Explorer
            </span>
          </span>
        </a>
      </li>
      <li *ngIf="configurationService.currentSection === 'visuals'" class="mr-3 flex-1">
        <a
          [routerLink]="['/visuals/datasets']"
          class="block py-1 md:py-3 pl-1 align-middle no-underline border-b-2"
          [ngClass]="{
            'border-proTeal-500 text-proTeal-200': configurationService.currentPage === 'datasets' && configurationService.currentBrand === 'formkiq',
            'border-red-600 text-white': configurationService.currentPage === 'datasets' && configurationService.currentBrand === '24hourcharts',
            'border-gray-800 text-gray-50 hover:border-proTeal-500 hover:text-white': configurationService.currentPage !== 'datasets' && configurationService.currentBrand === 'formkiq',
            'border-gray-800 text-gray-300 hover:border-red-600 hover:text-white': configurationService.currentPage !== 'datasets' && configurationService.currentBrand === '24hourcharts'
          }"
          >
          <span class="text-xs md:text-base">
            Datasets
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>