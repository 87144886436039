import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuardService } from './guards/authentication-guard.service';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./sections/dashboard/dashboard.module')
        .then(m => m.DashboardModule),
    data: {
      title: 'Dashboard'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'confirmRegistration',
    component: ChangePasswordComponent,
    data: {
      status: 'setUpPassword'
    }
  },
  {
    path: 'documents',
    loadChildren: () =>
      import('./sections/documents/documents.module')
        .then(m => m.DocumentsModule),
    data: {
      title: 'Documents'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'integrations',
    loadChildren: () =>
      import('./sections/integrations/integrations.module')
        .then(m => m.IntegrationsModule),
    data: {
      title: 'Integrations'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'visuals',
    loadChildren: () =>
      import('./sections/visuals/visuals.module')
        .then(m => m.VisualsModule),
    data: {
      title: 'Visuals'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./sections/account/account.module')
        .then(m => m.AccountModule),
    data: {
      title: 'Account'
    },
    canActivate: [AuthenticationGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
