import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private config: AppConfig,
    private httpClient: HttpClient) {
    dayjs.extend(timezone);
    dayjs.extend(utc);
  }

  customer = null;
  currentSection = 'visuals';
  currentPage = 'datasets';
  currentPageTitle = 'Dashboard';

  showSidebarByDefault = true;

  // used to trigger session refresh (token and plan)
  // expected token length is 3600 seconds, so 0 means wait full hour to refresh/re-check
  triggerRefreshOnTimeLeft = 0;
  // TODO: count session and force expiry
  // sessionLengthInMinutes = 10;
  
  currentTimezoneValue = '';
  currentFormkiqType = '';
  currentFormkiqVersion = '';
  useFormatConverter = false;
  usePresets = false;

  public get currentSiteTitle(): string {
    let brand = this.config.getConfig("brand");
    return brand != null && brand === '24hourcharts' ? '24-Hour Charts' : 'FormKiQ';
  }

  public get currentBrand(): string {
    return this.config.getConfig("brand");
  }

  public get accountApiUrl(): string {
    return this.config.getConfig("url").authApi;
  }

  public get chartApiUrl(): string {
    return this.config.getConfig("url").chartApi;
  }

  public get dataApiUrl(): string {
    return this.config.getConfig("url").documentApi;
  }

  public get wsApiUrl(): string {
    return this.config.getConfig("url").webSocketApi;
  }

  public get currentTimezone(): string {
    if (!this.currentTimezoneValue.length) {
      this.currentTimezoneValue = dayjs.tz.guess();
    }
    return this.currentTimezoneValue;
  }

  public getUtcOffset(tz: string = null): string {
    if (!tz) {
      tz = this.currentTimezone;
    }
    return dayjs().tz(tz).format('Z').replace(':', '');
  }

  // NOTE: not currently in use
  public determineRouteData(router, route) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((value: NavigationEnd) => {
      const routeData = route.root.firstChild.snapshot.data;
      console.log(routeData);
    });
  }

  // public setBrand(brandName): void {
  //   this.currentBrand = brandName;
  //   switch (brandName) {
  //     case '24hour':
  //       this.currentSiteTitle = 'The 24-Hour Webhook Console';
  //       break;
  //     case 'formkiq':
  //       this.currentSiteTitle = 'FormKiQ Console';
  //       break;
  //     default:
  //       this.currentSiteTitle = '';
  //       break;
  //   }
  // }

  public setPage(url) {
    let showSidebar = true;
    switch (url) {
      case '/documents':
      case '/documents/recent':
        this.currentSection = 'documents';
        this.currentPage = 'documentsRecent';
        this.currentPageTitle = 'Recent Documents';
        break;
      case '/documents/search':
        this.currentSection = 'documents';
        this.currentPage = 'documentsSearch';
        this.currentPageTitle = 'Document Search';
        break;
      case '/documents/untagged':
        this.currentSection = 'documents';
        this.currentPage = 'documentsSearch';
        this.currentPageTitle = 'Untagged';
        break;
      case '/documents/add':
        this.currentSection = 'documents';
        this.currentPage = 'documentsAdd';
        this.currentPageTitle = 'Add Documents';
        break;
      case '/documents/tagging':
        this.currentSection = 'documents';
        this.currentPage = 'documentsTagging';
        this.currentPageTitle = 'Document Tagging';
        showSidebar = false;
        break;
      case '/integrations':
      case '/integrations/webhooks':
        this.currentSection = 'integrations';
        this.currentPage = 'webhooks';
        this.currentPageTitle = 'Webhooks';
        break;
      case '/integrations/api':
        this.currentSection = 'integrations';
        this.currentPage = 'apiExplorer';
        this.currentPageTitle = 'API Explorer';
        break;
      case '/visuals':
      case '/visuals/datasets':
        this.currentSection = 'visuals';
        this.currentPage = 'datasets';
        this.currentPageTitle = 'Datasets';
        break;
      case '/visuals/charts':
        this.currentSection = 'visuals';
        this.currentPage = 'chartsExplorer';
        this.currentPageTitle = 'Charts Explorer';
        break;  
      default:
        this.currentSection = 'dashboard';
        this.currentPage = 'dashboard';
        this.currentPageTitle = 'Dashboard';
        showSidebar = false;
        break;
    }
    if (url.indexOf('/documents/info') > -1) {
      this.currentSection = 'documents';
      this.currentPage = 'documentInfo';
      this.currentPageTitle = 'Document Info';
    } else if (url.indexOf('visuals/charts') > -1) {
      this.currentSection = 'visuals';
      this.currentPage = 'datasets';
      this.currentPageTitle = 'Datasets - Chart';
      if (url.indexOf('visuals/charts/') > -1) {
        showSidebar = false;
      }
    }
    if (showSidebar && this.currentBrand != '24hourcharts') {
      this.showSidebarByDefault = true;
    } else {
      this.showSidebarByDefault = false;
    }
    if (this.currentPage === 'dashboard' && this.currentBrand === '24hourcharts') {
      this.currentSection = 'visuals';
      this.currentPage = 'datasets';
      this.currentPageTitle = 'Datasets';
    }
  }

  getHttpOptions(accessToken) {
    return {
      headers: new HttpHeaders({
        Authorization: accessToken
      })
    };
  }

  getCustomer(accessToken): Observable<any> {
    if (this.currentBrand === '24hourcharts') {

      if (this.customer == null) {
        this.customer = this.httpClient
          .get<any>(this.chartApiUrl + '/customers', this.getHttpOptions(accessToken))
          .pipe(shareReplay(1));
      }

      return this.customer;
      
    } else {
      return EMPTY;
    }
  }
}
