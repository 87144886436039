import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationInfoType } from '../../services/notification.schema';
import { MustMatch } from '../../helpers/must-match.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    route.data.pipe().subscribe(routeData => {
      if (routeData && routeData.status) {
        this.status = routeData.status;
      }
    });
  }

  status = 'changePassword';
  public form: FormGroup;
  formSubmitted = false;
  username = '';
  resetCode = 0;
  clientId = '';
  session = '';
  userStatus = '';

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      oldPassword: ['', [Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'passwordConfirmation')
    });
    this.route.queryParams.subscribe((params) => {
      if (params.code) {
        this.username = params.username;
        this.resetCode = params.code;
        this.clientId = params.clientId;
        this.userStatus = params.userStatus;
        this.session = params.session;
      }
      
      if (this.resetCode > 0 && (!this.username || !this.username.length || !this.clientId || !this.clientId.length)) {
        this.notificationService.createNotification(
          NotificationInfoType.Warning,
          'Please check your reset password link and try again.',
          2000,
          false
        );
        this.router.navigate(['/login']);
      }
      if (this.resetCode == 0 && !this.authenticationService.currentUser) {
        this.notificationService.createNotification(
          NotificationInfoType.Warning,
          'Please sign in to manage your account.',
          2000,
          false
        );
        this.router.navigate(['/login']);
      }
    });
  }

  changePassword() {
    this.formSubmitted = true;
    if (!this.form.controls.oldPassword.errors && !this.form.controls.password.errors && !this.form.controls.passwordConfirmation.errors) {
      if (this.resetCode === 0) {
        if (!this.form.controls.oldPassword.value.length) {
          this.form.controls.oldPassword.setErrors({'incorrect': true});
          return;
        }
        this.authenticationService.changePassword(this.authenticationService.currentUser.accessToken, this.form.get('oldPassword').value, this.form.get('password').value, this).subscribe((result: any) => {
          if (result.error) {
            this.notificationService.createNotification(
              NotificationInfoType.Danger,
              result.error.message,
              2000,
              false
            );
          } else {
            this.notificationService.createNotification(
              NotificationInfoType.Success,
              'Your password has been changed.',
              1500,
              false
            );
            this.router.navigate(['/account/settings'])
          }
        });

      } else {
        
        if (this.session != null && this.session.length > 0) {
          this.authenticationService.respondToAuthChallenge(this.username, this.userStatus, this.session, this.form.get('password').value, this).subscribe((result: any) => {
            if (result.error) {
              this.notificationService.createNotification(
                NotificationInfoType.Danger,
                result.error.message,
                2000,
                false
              );
            } else {
              this.notificationService.createNotification(
                NotificationInfoType.Success,
                'Your password has been set. Please log in.',
                1500,
                false
              );
              this.router.navigate(['/login'])
            }
          });

        } else {
          this.authenticationService.changePasswordFromReset(this.username, this.clientId, this.resetCode, this.form.get('password').value, this).subscribe((result: any) => {
            if (result.error) {
              this.notificationService.createNotification(
                NotificationInfoType.Danger,
                result.error.message,
                2000,
                false
              );
            } else {
              this.notificationService.createNotification(
                NotificationInfoType.Success,
                'Your password has been reset. Please log in.',
                1500,
                false
              );
              this.router.navigate(['/login'])
            }
          });
        }
      }
    }
  }

  handleApiError(errorResponse: HttpErrorResponse) {
    return new Observable((observer) => {
      observer.next(errorResponse);
    });
  }

}
