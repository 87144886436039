<app-navbar></app-navbar>
<app-notification></app-notification>
<div class="flex flex-col md:flex-row">
  <app-sidebar></app-sidebar>
  <div
    class="main-content h-screen flex-1 bg-white mt-28 md:mt-2"
    [ngClass]="{
      'md:ml-48 px-4 md:px-8 pt-6 md:pt-18':
        authenticationService.currentUser && configurationService.showSidebarByDefault,
      'pt-4 md:pt-16': 
        !authenticationService.currentUser || !configurationService.showSidebarByDefault
    }"
    >
    <router-outlet></router-outlet>
  </div>
</div>