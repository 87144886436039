import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationInfoType } from '../../services/notification.schema';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService
  ) { }

  public form: FormGroup;
  formSubmitted = false;
  redirectUrl = 'documents';

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]]
    });
  }

  sendPasswordReset() {
    this.formSubmitted = true;
    if (!this.form.controls.email.errors) {
      this.authenticationService.forgotPassword(this.form.get('email').value, this).subscribe((result: any) => {
        if (result.error) {
          if (result.error.code === 'LimitExceededException') {
            this.notificationService.createNotification(
              NotificationInfoType.Danger,
              'You have tried this too often. Please try again later.',
              0,
              true
            );
          } else {
            this.notificationService.createNotification(
              NotificationInfoType.Warning,
              'There is a problem with our password reset service. Please try again later',
              2000,
              false
            );
          }
        } else if (result.message) {
          this.notificationService.createNotification(
            NotificationInfoType.Success,
            'Please check your email for a link to reset your password.',
            0,
            true
          );
          this.router.navigate(['/']);
        } else {
          this.notificationService.createNotification(
            NotificationInfoType.Warning,
            'There is a problem with our password reset service. Please try again later',
            0,
            true
          );
        }
      });
    }
  }

  handleApiError(errorResponse: HttpErrorResponse) {
    return new Observable((observer) => {
      observer.next(errorResponse);
    });
  }

}
