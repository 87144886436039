import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApiService } from './services/api.service';
import { AuthenticationService } from './services/authentication.service';
import { ConfigurationService } from './services/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private apiService: ApiService,
    public authenticationService: AuthenticationService,
    public configurationService: ConfigurationService,
    private title: Title,
    private router: Router
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      ).subscribe((value: NavigationEnd) => {
        this.configurationService.setPage(value.url);
        this.title.setTitle(`${this.configurationService.currentPageTitle} - ${this.configurationService.currentSiteTitle}`);
      }
    );
  }

  private authenticationChangeSubscription: Subscription = null;

  ngOnInit(): void {
    // this.authenticationChangeSubscription = this.authenticationService.authenticationChange$.subscribe(
    //   () => {
    //     if (this.authenticationService.currentUser) {
    //       this.determinePlanAndVersion();
    //     } else {
    //       this.configurationService.currentFormkiqType = '';
    //       this.configurationService.currentFormkiqVersion = '';
    //     }
    //   }
    // );
  }

  ngOnDestroy() {
    this.authenticationChangeSubscription.unsubscribe();
  }

  // determinePlanAndVersion() {
  //   if (!this.configurationService.currentAccount) {
  //     this.apiService.getAllSites('', this).subscribe((data: any) => {
  //       if (data.sites.length) {
  //         this.configurationService.currentAccount = data.sites[0]; 
  //       }
  //     });
  //   }
  //   if (!this.configurationService.currentFormkiqVersion.length) {
  //     this.apiService.getVersion(this).subscribe((data: any) => {
  //       if (data.type) {
  //         this.configurationService.currentFormkiqType = data.type;
  //         this.configurationService.currentFormkiqVersion = data.version;
  //       }
  //     });
  //   }
  // }

  handleApiError(errorResponse: HttpErrorResponse) {
    return new Observable((observer) => {
      observer.next(errorResponse);
    });
  }

}
