<h2 *ngIf="resetCode === 0" class="p-4 text-base font-semibold text-gray-700 uppercase mb-1">
  <a [routerLink]="['/']" class="hover:text-gray-900">
  Home</a>
  <small class="px-1 text-xl font-thin text-gray-600"> &gt; </small>
  <a [routerLink]="['/account/']" class="hover:text-gray-900">
  Account</a>
  <small class="px-1 text-xl font-thin text-gray-600"> &gt; </small>
  <a [routerLink]="['/account/settings']" class="hover:text-gray-900">
  Settings</a>
</h2>
<h1 class="pl-4 text-xl md:text-2xl mb-4 font-bold">
  <span *ngIf="status === 'setUpPassword'">
    Set Your Password
  </span>
  <span *ngIf="status === 'changePassword'">
    Change Password
  </span>
</h1>
<form [formGroup]="form">
  <div
    *ngIf="resetCode === 0"
    class="md:flex md:items-center mx-4 mb-4 relative"
    >
    <div class="md:w-1/3">
      <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="oldPassword">
        Current Password
      </label>
    </div>
    <div class="md:w-2/3 lg:w-1/2">
      <input
        aria-label="Current Password"
        name="oldPassword"
        formControlName="oldPassword"
        type="password"
        required
        class="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-600
            placeholder-gray-500 text-gray-900 rounded-t-md
            focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-xl sm:leading-5"
        placeholder="******"
        [ngClass]="{ 'bg-red-200': this.formSubmitted && this.form.controls.oldPassword.errors }"
        />
    </div>
  </div>
  <div class="md:flex md:items-center mx-4 mb-4 relative">
    <div class="md:w-1/3">
      <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="password">
        New Password
      </label>
    </div>
    <div class="md:w-2/3 lg:w-1/2">
      <input
        aria-label="Password"
        name="password"
        formControlName="password"
        type="password"
        required
        class="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-600
            placeholder-gray-500 text-gray-900 rounded-t-md
            focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-xl sm:leading-5"
        placeholder="******"
        [ngClass]="{ 'bg-red-200': this.formSubmitted && this.form.controls.password.errors }"
        />
    </div>
  </div>
  <div class="md:flex md:items-center mx-4 mb-4 relative">
    <div class="md:w-1/3">
      <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="passwordConfirmation">
        Repeat New Password
      </label>
    </div>
    <div class="md:w-2/3 lg:w-1/2">
      <input
        aria-label="Repeat Password"
        name="passwordConfirmation"
        formControlName="passwordConfirmation"
        type="password"
        required
        class="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-600
            placeholder-gray-500 text-gray-900 rounded-t-md
            focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-xl sm:leading-5"
        placeholder="******"
        [ngClass]="{ 'bg-red-200': this.formSubmitted && this.form.controls.passwordConfirmation.errors }"
        />
    </div>
  </div>
  <div class="mt-5 sm:mt-8 flex justify-center">
    <button
      (click)="changePassword()"
      class="px-8 py-3 mx-1 border border-transparent text-base leading-6 font-medium rounded-md shadow
        text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline
        transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
      >
      <span *ngIf="status === 'setUpPassword'">
        Set Password
      </span>
      <span *ngIf="status === 'changePassword'">
        Change My Password
      </span>
    </button>
  </div>
</form>