import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationInfoType } from '../../services/notification.schema';
import { ConfigurationService } from '../../services/configuration.service';
import { MustMatch } from '../../helpers/must-match.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  public form: FormGroup;
  formSubmitted = false;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'passwordConfirmation')
    });

    if (this.configurationService.currentBrand === '24hourcharts') {
      this.router.navigate(['/'])
    }
  }

  register() {
    this.formSubmitted = true;
    if (!this.form.controls.email.errors && !this.form.controls.password.errors && !this.form.controls.passwordConfirmation.errors) {
      this.authenticationService.register(this.form.get('email').value, this.form.get('password').value, this).subscribe((result: any) => {
        if (result.error) {
          this.notificationService.createNotification(
            NotificationInfoType.Danger,
            result.error.message,
            2000,
            false
          );
        } else {
          this.notificationService.createNotification(
            NotificationInfoType.Success,
            'Your account has been created. Please check your email to verify your account.',
            1500,
            false
          );
          this.router.navigate(['/'])
        }
      });
    }
  }

  handleApiError(errorResponse: HttpErrorResponse) {
    return new Observable((observer) => {
      observer.next(errorResponse);
    });
  }

}
