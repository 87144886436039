<div
  class="w-full fixed top-0 z-40 mt-32 md:mt-14 flex justify-center"
  *ngIf="notificationInfo !== null"
  >
  <label
    class="relative close cursor-pointer flex items-center justify-center mx-4 px-4 md:px-20 py-4 shadow text-lg text-white border border-yellowGray-500"
    [ngClass]="{
      'bg-red-600': notificationInfo.type === 'danger',
      'bg-green-500': notificationInfo.type === 'success',
      'bg-yellow-500': notificationInfo.type === 'warning',
      'bg-white': notificationInfo.type === 'info'
      }"
    title="close"
    >
    {{ notificationInfo.message }}
    <div class="ml-8">
      <svg *ngIf="notificationInfo.isDismissible" (click)="notificationInfo = null" class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
      </svg>
    </div>
  </label>
</div>